.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 32px;
  width: 30%;
  height: 50px;
  background: linear-gradient(98deg, #FFF 0%, #7DD0FF 49.18%, #949EFF 93.68%);
}

.text {
  color: black;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 92.33%; /* 88.637px */
}